import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import {signOut} from '../../../store/actions/authentication/authActions';

const SignedInLinks = props => {
  const initials = props.userInfo && props.userInfo.initials ? props.userInfo.initials : 'RS';
  const accountPreviewIcon = props.auth.photoURL ? <img src={props.auth.photoURL} alt="User's account icon" /> : initials;

  return (
    <ul className="col TopNavigation__list" onClick={(e) => props.clickHandler(e)}>
      <li className="TopNavigation__item privacy">
        <Link to="/privacy-policy" className="TopNavigation__link">
          Privacy Policy
        </Link>
      </li>

      <li className="TopNavigation__item">
        <Link to="/true-trimmed-tracker" className="TopNavigation__link">
          True Trimmed Tracker
        </Link>
      </li>

      <div className="TopNavigation__itemGroup">
        <li className="TopNavigation__item">
          <a href="/" className="TopNavigation__link" onClick={props.signOut}>
            <i className="fa fa-sign-out"></i>
          </a>
        </li>

        <li className="TopNavigation__item TopNavigation__item--circle">
          <Link to="/account" className="TopNavigation__link">
            {accountPreviewIcon}
          </Link>
        </li>
      </div>
    </ul>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(null, mapDispatchToProps)(SignedInLinks);
