import React, {Component} from 'react';

import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <div className="Footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              <div className="footer-row">
                <div className="copyright-info">
                  <p>© <span>Made by</span> Tom <span className="mobile-info">(RSN: Mr Frosty)</span><span>(RSN: Mr Frosty / Discord tag: tvlfrosty#0001)</span></p>
                </div>

                <div className="social-info">
                  <a target="_blank" href="https://discord.gg/uUUMPbd" title="Come chat with us in the Achievement Help Discord" rel="noopener noreferrer">
                    <img src="https://truetrimmed.com/images/social/discord-squared.png" alt="Discord logo" />
                  </a>

                  <a target="_blank" href="https://twitter.com/tvlfrosty" title="Come chat with me on Twitter!" rel="noopener noreferrer">
                    <img src="https://truetrimmed.com/images/social/twitter-squared.png" alt="Twitter logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
