const updatesReducer = (state = {updates:{}, isLoading:true}, action) => {
  switch (action.type) {
    case 'GET_UPDATES_FULFILLED':
      const newState = Object.assign({}, state, {isLoading: false});

      Object.keys(action.updates).forEach(function(key) {
        if (action.updates[key].length === undefined) {
          action.updates[key] = Object.values(action.updates[key])
        }
      })

      newState.updates = action.updates;
      return newState;
    case 'GET_UPDATES_ERROR':
      console.log('Update error', action.err);
      return state;
    case 'CREATE_UPDATE_FULFILLED':
      console.log(action);
      return state;
    case 'CREATE_UPDATE_ERROR':
      console.log('Update error', action.err);
      return state;
    default:
      return state;
  }
}

export default updatesReducer;
