import {combineReducers} from 'redux';
import {firebaseReducer} from 'react-redux-firebase';

import updatesReducer from './updatesReducer';
import authReducer from './authReducer';
import achievementsReducer from './achievementsReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  updates: updatesReducer,
  achievements: achievementsReducer,
  firebase: firebaseReducer
})

export default rootReducer;
