import {Route, Redirect} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';

const BlockedRouteWhenLoggedOut = ({component: Component, ...rest}) => {
  const auth = useSelector(state => state.firebase.auth);

  return (
    <Route {...rest} render={(props) => isLoaded(auth) && !isEmpty(auth)
      ? <Component {...props} {...rest} />
      : <Redirect to={{pathname: '/maintenance', state: {from: props.location}}} />
    } />
  )
}

export default BlockedRouteWhenLoggedOut;
