// Importing core functions & components
import React, {Component} from 'react';

// Importing custom components
import Loader from 'react-loader-spinner';
import UpdateList from '../../components/updates/UpdatesList';

// Importing stylesheets
import './Homepage.css';

class Homepage extends Component {
  constructor(props){
    super(props);

    this.state = {
      currentUpdates: {
        latestUpdates: [],
        upcomingUpdates: []
      },
      currentPage: null
    }
  }

  onPageChanged = data => {
    const {currentUpdates} = this.state;
    const {updates:{updates}} = this.props;
    const {currentPage, pageLimit, type} = data;
    const offset = (currentPage - 1) * pageLimit;

    currentUpdates[type] = updates[type].slice(offset, offset + pageLimit);

    this.setState({currentUpdates});
  }

  componentDidMount() {
    this.props.onGetUpdates();
  }

  render() {
    const {currentUpdates} = this.state;
    const {updates:{updates}, isLoading} = this.props;

    if (isLoading) {
      return (
        <div className="LoadingScreen">
          <h3>Loading updates</h3>
          <Loader type="TailSpin" color="#00BFFF" height={100} width={100} />
        </div>
      )
    }

    const {latestUpdates, upcomingUpdates} = updates;

    return (
      <div className="Homepage">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1">
              <div className="row UpdatesRow">
                <UpdateList
                  title={'Patch notes:'}
                  updates={latestUpdates}
                  updatesToList={currentUpdates.latestUpdates}
                  updateType={'LatestUpdates'}
                  pageLimit={4}
                  pageNeighbours={1}
                  changePage={this.onPageChanged}
                />

                <UpdateList
                  title={'Coming updates:'}
                  updates={upcomingUpdates}
                  updatesToList={currentUpdates.upcomingUpdates}
                  updateType={'UpcomingUpdates'}
                  pageLimit={4}
                  pageNeighbours={1}
                  changePage={this.onPageChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Homepage
