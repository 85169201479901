// Importing core functions & components
import { connect } from 'react-redux';

// Importing custom functions
import {getUpdates} from '../store/actions/updates/updateActions';

// Importing custom components
import Homepage from '../pages/homepage/Homepage';

function mapStateToProps(state) {
  return {
    isLoading: state.updates.isLoading,
    updates: state.updates
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetUpdates: () => dispatch(getUpdates()),
  };
}

const HomepageContainer = connect(mapStateToProps, mapDispatchToProps)(Homepage);

export default HomepageContainer;
