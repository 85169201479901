// Importing core functions & components

// Importing stylesheets
import './Maintenance.css';

const Maintenance = () => {
  return (
    <div className="Maintenance">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1">
            <div className="row">
              <div className="col Updates">
                <h1>Maintenance:</h1>

                <div className="row">
                  <div className="col-12 col-lg-6">
                    <p>There is currently some maintenance going on, and some of these functions will not be usable. Sorry for the inconvenience. In the meantime join us on discord to discuss anything True Trimmed related.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Maintenance;
