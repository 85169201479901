import {twitterProvider, database, auth} from '../../../config/fbConfig';

const getInitials = fullNameArray => {
  if (fullNameArray.length > 2) {
    return fullNameArray[0][0] + fullNameArray[1][0] + fullNameArray[2][0];
  } else if (fullNameArray.length === 2) {
    return fullNameArray[0][0] + fullNameArray[1][0];
  } else if (fullNameArray[0].length > 1) {
    return fullNameArray[0][0] + fullNameArray[0][1];
  }

  return fullNameArray[0];
}

export const logInWithEmail = (credentials) => {
  return (dispatch, getState) => {
    auth.signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    ).then((res) => {
      dispatch({type: 'LOGIN_SUCCESS'})

      return database.ref(`/users/${res.user.uid}`).once('value', snap => {
        const userInfo = snap.val();
        dispatch({type: 'GET_USERINFO_SUCCESS', userInfo})
      })
      .catch((error) => {
        dispatch({type: 'GET_USERINFO_ERROR', error})
      })
    }).catch((err) => {
      dispatch({type: 'LOGIN_ERROR', err});
    });
  }
}

export const logInWithTwitter = () => {
  return (dispatch, getState) => {
    auth.signInWithPopup(twitterProvider)
    .then((response) => {
      const usersRef = database.ref(`/users/${response.user.uid}`);

      const fullNameArray = response.user.displayName.split(" ");
      const initials = getInitials(fullNameArray);
      const lastNameArray = [...fullNameArray];
      let lastName = '';

      if (lastNameArray.length > 1) {
        lastNameArray.shift();
        lastName = lastNameArray.join(' ');
      }

      if (response.additionalUserInfo.isNewUser) {
        usersRef.set({
          firstName: fullNameArray[0],
          lastName: lastName,
          initials: initials,
          settings: {
            accountType: 'non-ironman',
            hideCompleted: false,
            showAccurate: false
          },
          achievements: [
            {
              id: '1388-achievement-AqpkD6NT3q4c9gK',
              category: [
                {name: 'generalTrueTrimmed'},
                {name: 'truetrimmed'}
              ]
            }
          ]
        })
      }

      return response;
    }).then((response) => {
      dispatch({type: 'LOGIN_SUCCESS'})

      return database.ref(`/users/${response.user.uid}`).once('value', snap => {
        const userInfo = snap.val();

        const completedAchievements = userInfo.achievements ? userInfo.achievements : userInfo.completedAchievements ? userInfo.completedAchievements : [];
        const totalTTCompletedAchievementsCount = completedAchievements.filter(achievement => {
          let categories = [];

          if (achievement.category) {
            categories = achievement.category.map(cat => cat.name)
          }

          return categories.includes('truetrimmed');
        }).length;

        dispatch({type: 'GET_USERINFO_SUCCESS', userInfo: {...userInfo, totalTTCompletedAchievementsCount}})
      })
      .catch((error) => {
        dispatch({type: 'GET_USERINFO_ERROR', error})
      })
    }).catch((err) => {
      dispatch({type: 'LOGIN_ERROR', err});
    });
  }
}

export const signUpWithEmail = (newUser) => {
  return (dispatch, getState) => {
    auth.createUserWithEmailAndPassword(
      newUser.email,
      newUser.password
    ).then((response) => {
      const usersRef = database.ref(`/users/${response.user.uid}`);

      usersRef.set({
        firstName: newUser.firstName !== '' ? newUser.firstName : "anonymous",
        lastName: newUser.lastName,
        initials: newUser.firstName !== '' ? newUser.firstName[0] + (newUser.lastName !== '' ? newUser.lastName[0] : newUser.firstName.length > 1 ? newUser.firstName[1] : '') : "",
        settings: {
          accountType: newUser.accountType !== '' ? newUser.accountType : "non-ironman",
          hideCompleted: false,
          showAccurate: false
        },
        achievements: [
          {
            id: '1388-achievement-AqpkD6NT3q4c9gK',
            category: [
              {name: 'generalTrueTrimmed'},
              {name: 'truetrimmed'}
            ]
          }
        ]
      })
    }).then((res) => {
      dispatch({type: 'USER_CREATION_SUCCESS', res});
    }).catch((err) => {
      dispatch({type: 'USER_CREATION_ERROR', err});
    });
  }
}

export const signOut = () => {
  return (dispatch, getState) => {
    auth.signOut().then(() => {
      dispatch({type:'SIGNOUT_SUCCESS'})
    }).catch(() => {
      dispatch({type:'SIGNOUT_ERROR'})
    })
  }
}

export const getUserInfo = (user) => {
  return dispatch => {
    return database.ref(`/users/${user}`).once('value', snap => {
      const userInfo = snap.val();

      let completedAchievements = userInfo.achievements ? userInfo.achievements : userInfo.completedAchievements ? userInfo.completedAchievements : [];

      // const completedAchievementsCount = completedAchievements.filter(achievement => {
      //   let categories = [];
      //
      //   if (achievement.category) {
      //     categories = achievement.category.map(cat => {
      //       return cat.name;
      //     })
      //   }
      //
      //   return !categories.includes('truetrimmed');
      // }).length;

      const totalTTCompletedAchievementsCount = completedAchievements.filter(achievement => {
        let categories = [];

        if (achievement.category) {
          categories = achievement.category.map(cat => {
            return cat.name;
          })
        }

        return categories.includes('truetrimmed');
      }).length;

      dispatch({
        type: 'GET_USERINFO_SUCCESS',
        userInfo: {
          ...userInfo, // completedAchievementsCount,
          totalTTCompletedAchievementsCount
        }
      })
    })
    .catch((error) => {
      dispatch({type: 'GET_USERINFO_ERROR', error})
    });
  }
}
