import {database} from '../../../config/fbConfig';

export const getUpdates = () => {
  console.log(database);

  return dispatch => {
    return database.ref('/updates').once('value', snap => {
      const updates = snap.val();
      dispatch({type: 'GET_UPDATES_FULFILLED', updates});
    })
    .catch((error) => {
      dispatch({type: 'GET_UPDATES_ERROR', error});
    });
  }
}

export const createUpdate = (update) => {
  return dispatch => {
    const updatesRef = database.ref(`/updates/${update.type}`);
    updatesRef.push({title:update.title, description: update.description})
    .then(() => {
      dispatch({type: 'CREATE_UPDATE_FULFILLED', update});
    })
    .catch((error) => {
      dispatch({type: 'CREATE_UPDATE_ERROR', error});
    });
  }
}
