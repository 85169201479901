const initState = {
  authError: null
}

const authReducer = (state = initState, action) => {
  switch(action.type){
    case 'LOGIN_ERROR':
      console.log('login error', action);
      return {
        ...state,
        authError: 'Login failed'
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        authError: null
      }
    case 'SIGNOUT_SUCCESS':
      return state;
    case 'SIGNOUT_ERROR':
      console.log('logout error');
      return state;
    case 'USER_CREATION_SUCCESS':
      return {
        ...state,
        authError: null
      }
    case 'USER_CREATION_ERROR':
      console.log('Sign up error');
      return {
        ...state,
        authError: action.err.message
      }
    case 'GET_USERINFO_SUCCESS':
      return {
        ...state,
        userInfo: action.userInfo
      }
    case 'GET_USERINFO_ERROR':
      console.log('Get userinfo failed', action.error);
      return state
    default:
      return state
  }
};

export default authReducer;
