const initState = {
  fullAchievementsList: [],
  achievements: [],
  maxRunescore: 0,
  maxAchievementCount: 0,
  trueTrimmedAchievementsCount: 0,
  isLoading: true
}

const achievementsReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_ACHIEVEMENTS_FULFILLED':
      const achievementsWithSubs = action.accountSpecificAchievements.filter(achievement => achievement.subAchievements !== undefined);
      const achievementsList = action.accountSpecificAchievements.map(achievement => achievement);
      const subAchievements = [...new Set([].concat(...achievementsWithSubs.map((o) => o.subAchievements)))];

      let remainingSubAchievements = subAchievements.filter(subAchievement => {
        const subs = achievementsList.map(achievement => achievement.id)

        return !subs.includes(subAchievement.id);
      })

      remainingSubAchievements = remainingSubAchievements.filter((sub, index, self) => {
        const subWithScore = self.findIndex(t => t.id === sub.id && sub.runescore !== undefined);
        const doubleSubWithScore = self.findIndex(t => t.id === sub.id && t.runescore !== undefined);

        if (subWithScore === -1) {
          if (doubleSubWithScore !== -1) {
            return null;
          }

          return sub; // self.findIndex(t => t.id === sub.id) <-- causes first subachievement to be filtered out.
        }

        return doubleSubWithScore;
      })

      const fullAchievementsList = achievementsList.concat(remainingSubAchievements);

      const fullAchievementsCount = fullAchievementsList.filter(achievement => {
        const categories = achievement.category.map(cat => {
          return cat.name;
        })

        return categories.includes('truetrimmed');
      }).length;

      return {
        ...state,
        achievements: action.accountSpecificAchievements,
        maxRunescore: action.maxRunescore,
        maxAchievementCount: action.maxAchievementCount,
        trueTrimmedAchievementsCount: action.trueTrimmedAchievementsCount,
        totalTrueTrimmedAchievementsCount: fullAchievementsCount,
        fullAchievementsList: fullAchievementsList,
        isLoading: false
      }
    case 'UPDATE_USERSETTINGS_HIDECOMPLETED_FULFILLED':
      return {
        ...state,
        isLoading: false,
        hideCompleted: action.hideCompleted
      }
    case 'UPDATE_USERSETTINGS_SHOWACCURATE_FULFILLED':
      return {
        ...state,
        isLoading: false,
        showAccurate: action.showAccurate
      }
    case 'ADD_ACHIEVEMENT_FULFILLED':
      return {
        ...state,
        userInfo: {
          achievements: action.achievements
        }
      };
    case 'ADD_ACHIEVEMENT_ERROR':
      console.log('Update error', action.error);
      return state;
    case 'REMOVE_ACHIEVEMENT_FULFILLED':
      return {
        ...state,
        userInfo: {
          achievements: action.achievements
        }
      };
    case 'REMOVE_ACHIEVEMENT_ERROR':
      console.log('Update error', action.error);
      return state;
    case 'GET_ACHIEVEMENTS_ERROR':
      console.log('Update error', action.error);
      return state;
    case 'UPDATE_USERSETTINGS_ERROR':
      console.log('Update usersettings error', action.error);
      return state;
    default:
      return state;
  }
}

export default achievementsReducer;
