import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';

import './TopNavigation.css';

class TopNavigation extends Component {
  constructor() {
    super();

    this.state = {
      mobileMenuIsToggled: false
    }
  }

  componentDidMount() {
    // fix to prevent menu from staying open
    this.setState({
      mobileMenuIsToggled: false
    })
  }

  clickHandler = e => {
    const {mobileMenuIsToggled} = this.state;

    if (!e.target.classList.contains('menu-btn')) {
      this.setState({
        mobileMenuIsToggled: false
      })
    }

    this.setState({
      mobileMenuIsToggled: !mobileMenuIsToggled
    })
  }

  logoClickHandler = () => {
    this.setState({
      mobileMenuIsToggled: false
    })
  }

  render() {
    const {mobileMenuIsToggled} = this.state;
    const {auth, userInfo} = this.props;
    const isToggled = mobileMenuIsToggled ? 'open' : '';
    const links = auth.uid ? <SignedInLinks clickHandler={this.clickHandler} auth={auth} userInfo={userInfo} /> : <SignedOutLinks clickHandler={this.clickHandler} />;

    return (
      <div className={`TopNavigation ${isToggled}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1">
              <div className="row">
                <div className="col-8 col-sm-6 col-md-4 col-lg-2 TopNavigation__logo" onClick={this.logoClickHandler}>
                  <Link to="/">
                    <img src="https://truetrimmed.com/images/site/logo.png" alt="True Trimmed's logo" />
                  </Link>
                </div>

                {links}

                <div className="menu-btn" onClick={this.clickHandler}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopNavigation;
