import React from "react";
import ReactDOM from "react-dom";
import { Provider, useSelector } from "react-redux";
import firebase from './config/fbConfig';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { ReactReduxFirebaseProvider, isLoaded } from 'react-redux-firebase';
import rootReducer from './store/reducers/rootReducer';

import App from './App';

const initialState = {}

const rrfConfig = {
  userProfile: 'users'
}

const store = createStore(rootReducer, initialState, applyMiddleware(thunk));

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch
}

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth);

  if (!isLoaded(auth)) return <div>Loading Screen...</div>;
  return children;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <AuthIsLoaded>
          <App />
        </AuthIsLoaded>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
