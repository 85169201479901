// Importing core functions & components
import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';

//Importing custom functions
import {getUserInfo} from './store/actions/authentication/authActions';

// Importing custom components
import Loader from 'react-loader-spinner';
import TopNavigationContainer from './containers/TopNavigationContainer';
import Footer from './components/common/footer/Footer';
// import BlockedRouteWhenLoggedIn from './components/common/route/BlockedRouteWhenLoggedIn';
import BlockedRouteWhenLoggedOut from './components/common/route/BlockedRouteWhenLoggedOut';

// Importing page templates
import HomepageContainer from './containers/HomepageContainer';
import Maintenance from './pages/maintenance/Maintenance';
// import LoginContainer from './containers/LoginContainer';
// import SignUpContainer from './containers/SignUpContainer';
// import CreateUpdateContainer from './containers/CreateUpdateContainer';
// import AchievementTrackerContainer from './containers/AchievementTrackerContainer';

// Importing stylesheets
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import './App.css';

class App extends Component {
  constructor(){
    super();

    this.state = {}
  }

  componentDidMount() {
    const {auth, onGetUserInfo, userInfo} = this.props;

    if (auth.uid && !userInfo) {
      onGetUserInfo(auth.uid);
    }
  }

  render() {
    const {auth, userInfo} = this.props;
    const authenticated = auth.uid ? true : false;

    if (authenticated && !userInfo) {
      return (
        <div className="LoadingScreen">
          <h3>Loading userdata</h3>
          <Loader type="TailSpin" color="#00BFFF" height={100} width={100} />
        </div>
      )
    }

    return (
      <Router>
        <div className="App">
          <TopNavigationContainer userInfo={userInfo} />

          <Switch>
            <Route exact path='/' render={() => <HomepageContainer />} />
            <Route exact path="/maintenance" component={Maintenance} />
            <BlockedRouteWhenLoggedOut exact path='/true-trimmed-tracker' authenticated={false} component={HomepageContainer} />
            <BlockedRouteWhenLoggedOut exact path='/achievement-tracker' authenticated={false} component={HomepageContainer} />
            <BlockedRouteWhenLoggedOut exact path='/sign-in' authenticated={false} component={HomepageContainer} />
            <BlockedRouteWhenLoggedOut exact path='/sign-up' authenticated={false} component={HomepageContainer} />
            <BlockedRouteWhenLoggedOut exact path='/create-update' component={HomepageContainer} />
          </Switch>

          <Footer />
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.auth.userInfo,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUserInfo: (user) => dispatch(getUserInfo(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
