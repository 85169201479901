// Importing core functions & components
import React, {Component} from 'react';

// Importing custom components
import Pagination from '../pagination/Pagination';

// Importing stylesheets
import './UpdateList.css';

class  UpdatesList extends Component {
  renderUpdates = (update, index, type) => {
    return (
      <li key={`${type}Update-${index}`}>
        <h3>{update.title}</h3>

        <p>{update.description}</p>
      </li>
    )
  }

  render() {
    const {updates, updatesToList, updateType, title, pageLimit, pageNeighbours, changePage} = this.props;
    const updatesList = updatesToList.map((update, index) => this.renderUpdates(update, index, updateType));

    return (
      <div className={`${updateType} Updates col`}>
        <h1>{title}</h1>

        <ul className="UpdatesList">
          {updatesList}
        </ul>

        <Pagination
          type={updateType}
          totalRecords={updates.length}
          pageLimit={pageLimit}
          pageNeighbours={pageNeighbours}
          onPageChanged={changePage}
        />
      </div>
    );
  }
}

export default UpdatesList;
