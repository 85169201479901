import React from 'react';
import {Link} from 'react-router-dom';

const SignedOutLinks = props => {
  return (
    <ul className="col TopNavigation__list" onClick={(e) => props.clickHandler(e)}>
      <li className="TopNavigation__item privacy">
        <Link to="/privacy-policy" className="TopNavigation__link">
          Privacy Policy
        </Link>
      </li>

      {/*
      <div className="TopNavigation__itemGroup">
        <li className="TopNavigation__item">
          <Link to="/sign-up" className="TopNavigation__link">
            Sign up
          </Link>
        </li>

        <li className="TopNavigation__item">
          <Link to="/sign-in" className="TopNavigation__link">
            Sign in
          </Link>
        </li>
      </div>
    */}
    </ul>
  );
}

export default SignedOutLinks;
