// Importing core functions & components
import {connect} from 'react-redux';

// Importing custom components
import TopNavigation from '../components/common/header/TopNavigation';

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

const TopNavigationContainer = connect(mapStateToProps)(TopNavigation);

export default TopNavigationContainer;
